.tooltip {
  position: relative;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 4px;
  padding: 4px 8px;
  opacity: 0.9;
  white-space: nowrap;
}
/* Posicionar el control de zoom en la esquina inferior derecha */
.ol-zoom {
  top: 0.5em !important; 
  /*top: auto !important;*/
  left: auto !important;
  right: 0.5em !important;
}
.ol-popup {
  position: absolute;
  background-color: white;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 10px;
  pointer-events: none;
  box-shadow: 0 1px 4px rgba(0,0,0,.2);
  min-width: 200px;
   z-index : 999;
   white-space: nowrap;

}
.ol-popup-closer {
  position: absolute;
  top: 2px;
  right: 8px;
  border: none;
  background: white;
  cursor: pointer;
  z-index : 999;

}
#id-del-contenedor-del-iframe {
  position: relative;
  z-index: 1000; /* o cualquier número alto */
}


.ol-scale-line-inner{
  font-size: 11px !important; /* Ajusta el tamaño según tus preferencias */
  font-weight: bold;
  white-space: nowrap;

}





.ol-scale-line {
  right:0.5em;
  left:auto;

  background: rgba(255, 255, 255, 0.7);
  overflow: hidden;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding:5px !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=85)";
    -moz-opacity: 0.85;
    opacity: 0.85;
    height: 22px;
    padding: 2px !important;
    
}
.leyend-map{
  position: absolute;
  bottom: 50px;
  left: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  color: #333;
  background-color: #ffffff99;
  padding: 5px;
}

.full-center-map {
  top: 6.2em;
  right: 0.5em;
  background-color: white;
  border: solid 1px #ccc;
  border-radius: 4px;
  /* max-width: 2rem;
  max-height:2rem; */
}
.full-center-map-on-current-location {
  top: 8.2em;
  right: 0.5em;
  background-color: white;
  border: solid 1px #ccc;
  border-radius: 4px;
  /* max-width: 2rem;
  max-height:2rem; */
}
.ol-touch .ol-control button{
  font-size : 18px !important;
}
.full-draw-control{
  top: 8.2em;
  right: 0.5em;
  background-color: white;
  border: solid 1px #ccc;
  border-radius: 4px;
}
.ol-viewport{
  container-type: inline-size;

}

@container (max-width: 450px) {
  .clearDrawButton{
    display:none !important;
  }
  .clear-draw-control{
    display:none !important;
  }
}
.clearDrawButton{
  font-size : minmax(1rem, 1.5vh, 2.5rem);
}
/* Estilo base del botón */
.clear-draw-control {
  padding: 10px 20px;
  font-size: 20px;
  font-weight: bold;
  background-color: #002a4a99; /* Color celeste */
  border-radius: 20px;
  border: 1px solid blue;
  cursor: pointer;
  bottom: 50px;
  right: 50%;
  transform: translateX(50%);
  transition: background-color 0.5s ease-in-out;
}




@container (max-width: 450px) {
  .exitDrawButton{
    display:none !important;
  }
  .exit-draw-control{
    display:none !important;
  }
}
.exitDrawButton{
  font-size : minmax(1rem, 1.5vh, 2.5rem);
}
/* Estilo base del botón */
.exit-draw-control {
  padding: 10px 20px;
  font-size: 20px;
  font-weight: bold;
  background-color: #002a4a99; /* Color celeste */
  border-radius: 20px;
  border: 1px solid blue;
  cursor: pointer;
  bottom: 50px;
  right: 50%;
  transform: translateX(50%);
  transition: background-color 0.5s ease-in-out;
}

/* Estilo del texto al pasar el mouse */
.exit-draw-control::before {
  background-color: rgba(255, 255, 255, 0.2); /* Efecto cristal oscurecido */
  pointer-events: none;
  
}

/* Efecto al pasar el mouse */
.exit-draw-control:hover {
  background-color: rgba(10, 10, 255, 0.85);
}

/* Efecto al hacer click */
.exit-draw-control:active {
  background-color: rgba(255, 255, 255, 0.6);
}

/* .exit-draw-control{
  bottom: 2.2em;
  right: 50%;
  transform: translateX(50%);
  background-color: blue;
  color: white;
  border: solid 1px #55c;
  border-radius: 4px;
} */
.full-draw-control-next-row{
  top: 10.2em;
  right: 0.5em;
  background-color: white;
  border: solid 1px #ccc;
  border-radius: 4px;
}
.full-full-screen-map-button{
  top: 4.2em;
  right: 0.5em;
  background-color: white;
  border: solid 1px #ccc;
  border-radius: 4px;
  display:flex;
  justify-content: left;
  /*width:100% !important;*/
   /*min-width:1.62rem;*/
  /*height:1.5rem; */
}
.geoapify-close-button	{
  display:none !important;
}
.geoapify-autocomplete-input-box	{
   position:absolute;
   top:25px;
   left:.5em;
   min-width: 10rem;
   min-height: 1rem;
   width:17rem;
   background-color: white;
   border: solid 1px #999;
   border-radius: 4px;
   box-shadow: 1rem 0 1em 0 rgba(0, 0, 0, 0.2);
   background-color: transparent;
   border: transparent;
   /*color:transparent;*/
}
.geoapify-autocomplete-input{
  position:absolute;
  top:0px !important;
  left:0px !important;
  border-radius: 0.25em;
  background-color: #fff;
  color:#000;
  box-shadow: 0 0 1em 0 rgba(0,0,0,.2);
  border:0;
}
.layer-changer-map{
  min-width: 8rem;
  min-height: 1rem;
  max-width:70vw;
  top: 30px;
  left: .5em;
  background-color: white;
  border: solid 1px #999;
  border-radius: 4px;
  box-shadow: 1rem 0 1em 0 rgba(0, 0, 0, 0.2);
}
.layer-changer-map-2row{
  min-width: 8rem;
  min-height: 1rem;
  max-width:70vw;
  top: 80px;
  left: .5em;
  background-color: white;
  border: solid 1px #999;
  border-radius: 4px;
  box-shadow: 1rem 0 1em 0 rgba(0, 0, 0, 0.2);
}

.layer-changer-selector{
  width: 100%;
  height: 100%;
  background-color: white;
  /* Reset */
  appearance: none;
  border: 0;
  outline: 0;
  font: inherit;
  /* Personalize */
  /*width: 20rem;*/
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  /*color: white;*/
  border-radius: 0.25em;
  box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
  /* Remove IE arrow */
  

}
.full-basemap-changer-map{
  /*min-width: 8rem;*/
  min-height: 1rem;
  bottom: 50px;
  right: .5em;
  background-color: white;
  border: solid 1px #999;
  border-radius: 4px;
  box-shadow: 1rem 0 1em 0 rgba(0, 0, 0, 0.2);
}
/* .basemap-changer-selector{
  width: 100%;
  height: 100%;
  background-color: white;
} */


.basemap-changer-selector{
  width: 100%;
  height: 100%;
  background-color: white;
  /* Reset */
  appearance: none;
  border: 0;
  outline: 0;
  font: inherit;
  /* Personalize */
  /*width: 20rem;*/
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  /*color: white;*/
  border-radius: 0.25em;
  box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
  /* Remove IE arrow */
  

}
.basemap-changer-selector-image{

  width: clamp(40px, 3.8vw, 80px);
  height: clamp(40px, 3.8vw, 80px);

  background-color: white;
  /* Reset */
  appearance: none;
  border: 1px;
  outline: 0;
  font: inherit;
  /* Personalize */
  /*width: 20rem;*/
  padding: 5px;
  /*color: white;*/
  border-radius: 0.25em;
  box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
  overflow: hidden;
  /* Remove IE arrow */
  object-fit: cover;

}
.basemap-changer-selector-image img{
  width:100%;
  height: 100%;
  object-fit: cover;
}
.full-full-screen-map-button::-ms-expand {
  display: none;
}
/* Remove focus outline */
.full-full-screen-map-button:focus {
  outline: none;
}
.full-full-screen-map-button:focus option {
  color: inherit;
  /*background-color: var(--option-bg);*/
}


.tooltip-icon {
  position: relative;
  display: inline-block;
  width: 1.5rem;

  
}
.tooltip-icon .tooltiptext {
  visibility: hidden;
  
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 2.5px;
  left: -14rem;
  width: 14rem;
  /*margin-left: -14rem; Use half of the width (120/2 = 60), to center the tooltip */
}

.tooltip-icon:hover .tooltiptext {
  visibility: visible;
}


.legend-text {
  margin: 5px 0;
  color: black;
  font-size: 12px;
}

.legend-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

/* Ajustes adicionales según sea necesario */

