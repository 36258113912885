@media only screen and (max-width: 960px) {
  .not_in_mobile {
    display: none;
    position: unset;
    visibility: hidden;
  }
}

.wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /*padding-top: 2rem;*/
}
.catastro_form_paper_box {
  background-color: var(--color-fith);
  /*max-width: var(--max-article-width);*/
  padding: 1rem;
  width: calc(100% - 2rem);
  min-height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 25rem;
  flex-direction: column;
}
.catastro_form_article {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.catastro_form_actions {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 1rem;
  padding-bottom: 1rem;
  justify-content: center;
  max-width: var(--max-article-width);
}
.catastro_form_location_autocomplete {
  flex: 1 1 180px;
  /*height: 100%;*/
  max-height: fit-content;
}
.catastro_form_analysis_type_selector {
  flex: 1 1 180px;
}
.info_box {
  height: 100%;
  width: 100%;
  /*max-height: 65vh;*/
  overflow-y: auto;
}
.graph_wrapper{
  display:flex; 
  flex-direction:column;
  width:100%; 
  container-type: inline-size;
}
@media (max-width: 2800px) and (min-width: 1600px){
  .graph_wrapper{
    flex-direction:row !important;

  }

}

@media (max-width: 1600px){
  .graph_wrapper{
    flex-direction:column !important;

  }

}